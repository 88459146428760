<template>
  <v-card class="mt-2 ml-2 mr-2">
    <FCustomerTable ref="refTabCustomerClick"/>
  </v-card>
</template>

<script>
import FCustomerTable from "../../components/mitra/customer/FCustomerTable";

export default {
  components: { FCustomerTable },
  data() {
    return {
      firstTabTitle: 'SUPPLIER',
      firstTabIcon: 'mdi-factory'
    }
  },
  methods: {
    tabCustomerClick(){
      try {
        this.$refs.refTabCustomerClick.fetchParent()
      }catch (e) {
        e.toString()
      }

    },

  }
}
</script>

<style scoped>
</style>